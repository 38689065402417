.menuContainer {
  position: relative;
  z-index: 1000;
}

.menuIcon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1100;
  flex-direction: column; 
}

.line {
  width: 2.5rem; 
  height: 0.15rem; 
  background-color: var(--text-color);
  margin: 0.2rem 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
}

/* Open state for menu lines */
.menuIcon.open .line:nth-child(1) {
  transform: rotate(45deg) translate(3px, 3px); 
}

.menuIcon.open .line:nth-child(2) {
  transform: rotate(-45deg) translate(3px, -3px);
}

.navMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-page);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none; 
/*   transition: opacity 0.5s ease, clip-path 0.5s ease; */
  z-index: 1000; 
}

.navMenu[style*="opacity: 1"] {
  pointer-events: all;
}

.navLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.navItem {
  margin: 1rem 0;
}

.navLink {
  text-decoration: none;
  color: var(--text-color);
  font-size: 4rem;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: var(--text-color);
}

.otherLinks {
  color: var(--text-color-hover);
}

.active {
  color: var(--royal-blue);
}

/* Responsive breakpoints */

/* Large Desktops */
@media (min-width: 1200px) {
  .menuIcon {
    top: 1.5rem;
    right: 1.5rem;
  }

  .navLink {
    font-size: 3.5rem;
  }
}

/* Tablets and small desktops */
@media (max-width: 1199px) and (min-width: 768px) {
  .menuIcon {
    top: 1.5rem;
    right: 1.5rem;
  }

  .navLink {
    font-size: 3rem;
  }

  .navItem {
    margin: 0.8rem 0;
  }
}

/* Mobile phones */
@media (max-width: 767px) {
  .menuIcon {
    top: 1rem;
    right: 1rem;
  }

  .navLink {
    font-size: 2.5rem;
  }

  .navItem {
    margin: 0.6rem 0;
  }
}

/* Small mobile phones */
@media (max-width: 480px) {
  .menuIcon {
    top: 0.8rem;
    right: 0.8rem;
  }

  .navLink {
    font-size: 2rem;
  }

  .navItem {
    margin: 0.4rem 0;
  }
}
